import axios from 'axios';
import { ScopeParams } from '@/models/Permission';
import { UserParams } from '@/models/User';

class Roles {
  async activate(role) {
    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/roles/${
      role.id
    }/activate`;
    const result = await axios.post(url);

    return result.data;
  }

  async create(role) {
    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/roles`;
    const result = await axios.post(url, role);

    return result.data;
  }

  async update(role) {
    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/roles/${role.id}`;
    const result = await axios.put(url, role);

    return result.data;
  }

  async deactivate(role) {
    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/roles/${
      role.id
    }/deactivate`;
    const result = await axios.post(url);

    return result.data;
  }

  async delete(role) {
    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/roles/${role.id}`;
    const result = await axios.delete(url);

    return result.data;
  }

  async find(roleId) {
    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/roles/${roleId}`;
    const result = await axios.get(url);

    return result.data;
  }

  async search(roleFilter) {
    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/roles?&name=${
      roleFilter.name
    }&index=${roleFilter.offset.index}&${roleFilter.offset.limit}`;
    const result = await axios.get(url);

    return result.data;
  }
}

class Scopes {
  async grant(role, scopes) {
    if (!scopes.length) return;

    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/roles/${
      role.id
    }/scopes/grant`;
    const result = await axios.post(url, new ScopeParams(scopes));

    return result.data;
  }

  async revoke(role, scopes) {
    if (!scopes.length) return;

    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/roles/${
      role.id
    }/scopes/revoke`;
    const result = await axios.post(url, new ScopeParams(scopes));

    return result.data;
  }
}

class Users {
  async find(hashId) {
    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/users/${hashId}`;
    const result = await axios.get(url);

    return result.data;
  }

  async activate(hashId) {
    const url = `${
      process.env.VUE_APP_ACCOUNT_API_URL
    }/users/${hashId}/activate`;
    const result = await axios.post(url);

    return result.data;
  }

  async deactivate(hashId) {
    const url = `${
      process.env.VUE_APP_ACCOUNT_API_URL
    }/users/${hashId}/deactivate`;
    const result = await axios.post(url);

    return result.data;
  }

  async save(user) {
    var url = `${process.env.VUE_APP_ACCOUNT_API_URL}/users`;
    const result = await axios.post(url, new UserParams(user));

    return result.data;
  }

  async update(user) {
    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/users/${user.id}`;
    const result = await axios.put(url, new UserParams(user));

    return result.data;
  }

  async search(userFilter) {
    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/users?fullName=${
      userFilter.fullName
    }&email=${userFilter.email}&taxDocument=${userFilter.taxDocument}&index=${
      userFilter.offset.index
    }&${userFilter.offset.limit}`;
    const result = await axios.get(url);

    return result.data;
  }
}

class Features {
  async getList() {
    const url = `${
      process.env.VUE_APP_ACCOUNT_API_URL
    }/accounts/hidden-features`;
    const result = await axios.get(url);
    return result;
  }
  async update(listFeatures) {
      const header = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const url = `${
      process.env.VUE_APP_ACCOUNT_API_URL
    }/accounts/hidden-features`;
    const send = {
      listFeatures: listFeatures,
    };
    const result = await axios.put(url, send, header);
    return result;
  }
}

export default class AccountApi {
  roles = new Roles();
  scopes = new Scopes();
  users = new Users();
  features = new Features();

  async signin(username, password, subdomain) {
    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/${
      process.env.VUE_APP_APPLICATION_ID
    }/signin`;
    let model = {
      login: username,
      password: password,
    };

    if (subdomain) {
      model.subdomain = subdomain;
    }

    const result = await axios.post(url, model);
    return result;
  }

  async recoverEmail(username, password) {
    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/recovery-email`;
    const model = {
      code: username,
      password: password,
    };
    const result = await axios.post(url, model);

    return result;
  }

  async whoAmI() {
    const result = await axios.get(
      `${process.env.VUE_APP_ACCOUNT_API_URL}/whoami`
    );
    return result.data;
  }

  async autoSignIn(hash, applicationId) {
    const result = await axios.post(
      `${process.env.VUE_APP_ACCOUNT_API_URL}/${applicationId}/signin/${hash}`
    );
    return result;
  }

  async sendEmailRecovery(email, appUrl, applicationId) {
    const url = `${
      process.env.VUE_APP_ACCOUNT_API_URL
    }/${applicationId}/send-email-recovery/`;
    const model = {
      email: email,
      appUrl: appUrl,
    };
    const result = await axios.post(url, model);

    return result;
  }

  async createPassword(password, token) {
    const customAxios = axios.create({
      headers: {
        Authorization: 'bearer ' + token,
      },
    });

    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/create-password`;
    const model = {
      password: password,
    };
    const result = await customAxios.post(url, model);

    return result;
  }

  async changePassword(oldPassword, password, authCode) {
    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/change-password`;
    const options = {
      headers: { authCode: authCode },
    };
    const model = {
      oldPassword: oldPassword,
      password: password,
    };
    const result = await axios.post(url, model, options);

    return result;
  }

  async sendSelfie(data) {
    const url = `${
      process.env.VUE_APP_ACCOUNT_API_URL
    }/confirmation/send-selfie/`;
    const formData = data;
    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await axios.post(url, formData, header);

    return result;
  }

  async sendDocumentFront(data) {
    const url = `${
      process.env.VUE_APP_ACCOUNT_API_URL
    }/confirmation/send-document-front/`;
    const formData = data;
    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await axios.post(url, formData, header);

    return result;
  }

  async sendDocumentBack(data) {
    const url = `${
      process.env.VUE_APP_ACCOUNT_API_URL
    }/confirmation/send-document-back/`;
    const formData = data;
    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const result = await axios.post(url, formData, header);

    return result;
  }

  async branchOfActivities() {
    const result = await axios.get(
      `${process.env.VUE_APP_ACCOUNT_API_URL}/business-activities`
    );
    return result.data;
  }

  async presets() {
    const result = await axios.get(
      `${process.env.VUE_APP_ACCOUNT_API_URL}/presets`
    );
    return result.data;
  }

  async findLogo() {
    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/presets/logo`;
    const result = await axios.get(url, {
      responseType: 'arraybuffer',
    });
    return result;
  }

  async sendLogo(data, authCode) {
    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/presets/send-logo/`;
    const formData = data;
    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'AuthCode': authCode,
      },
    };

    const result = await axios.post(url, formData, header);

    return result;
  }

  async deleteLogo(data, authCode) {
    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/presets/delete-logo/`;
    const formData = data;
    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'AuthCode': authCode,
      },
    };

    const result = await axios.post(url, formData, header);

    return result;
  }

  async savePreset(presets, authCode) {
    const options = {
      headers: { authCode: authCode },
    };

    return await axios.put(
      `${process.env.VUE_APP_ACCOUNT_API_URL_V3}/presets`,
      presets,
      options
    );
  }

  async banks() {
    const result = await axios.get(
      `${process.env.VUE_APP_ACCOUNT_API_URL}/banks`
    );
    return result.data;
  }

  async getBankData() {
    const result = await axios.get(
      `${process.env.VUE_APP_ACCOUNT_API_URL}/presets`
    );
    return result.data;
  }

  async checkPassword(password) {
    const model = {
      password: password,
    };
    const result = await axios.post(
      `${process.env.VUE_APP_ACCOUNT_API_URL}/check-password`,
      model
    );
    return result;
  }

  async do2FA(document, password, expiresIn) {
    const model = { document, password, expiresIn };
    const result = await axios.post(
      `${process.env.VUE_APP_ACCOUNT_API_URL}/2FA`,
      model
    );

    return result;
  }

  async getSellers() {
    const result = await axios.get(
      `${process.env.VUE_APP_ACCOUNT_API_URL}/partner/sellers`
    );
    return result.data;
  }

  async getSellersFromApp(userFilter) {
    let params = { ...userFilter };
    params.index = userFilter.offset.index;
    const result = await axios.post(
      `${process.env.VUE_APP_ACCOUNT_API_URL}/holders/list/app`,
      params
    );
    return result;
  }

  async getSellers({ length, index, fullName, taxDocument, email }) {
    let params = {};

    if (length) params.length = length;
    if (index) params.index = index;
    if (fullName) params.fullName = fullName;
    if (taxDocument) params.taxDocument = taxDocument;
    if (email) params.email = email;

    const result = await axios.get(
      `${process.env.VUE_APP_ACCOUNT_API_URL}/partner/sellers`,
      { params }
    );
    return result;
  }

  async getSeller(fullNames, taxDocuments, length, index) {
    let model = { fullNames, taxDocuments };

    if (length) model.length = length;
    if (index) model.index = index;

    const result = await axios.post(
      `${process.env.VUE_APP_ACCOUNT_API_URL}/partner/sellers`,
      model
    );
    return result;
  }

  async getAuthCode(parameters) {
    const model = parameters;
    const result = await axios.post(
      `${process.env.VUE_APP_ACCOUNT_API_URL}/auth-code`,
      model
    );

    return result;
  }

  async sendAuthCodeByType(parameters, authCodeType) {
    const model = parameters;
    const result = await axios.post(
      `${process.env.VUE_APP_ACCOUNT_API_URL}/auth-code/${authCodeType}`,
      model
    );

    return result;
  }

  async deleteBankAccount(authCode) {
    const header = {
      headers: {
        authCode: authCode,
      },
    };
    return await axios.post(
      `${process.env.VUE_APP_ACCOUNT_API_URL}/presets/delete-bank-account`,
      null,
      header
    );
  }

  async changeSellerHolder(model, holderId) {
    return await axios.put(
      `${
        process.env.VUE_APP_ACCOUNT_API_URL
      }/partner/sellers/${holderId}/change-holder`,
      model
    );
  }

  async deactivateSeller(holderId, forgetHolderData) {
    return await axios.put(
      `${
        process.env.VUE_APP_ACCOUNT_API_URL
      }/partner/sellers/${holderId}/deactivate`,
      { forgetHolderData }
    );
  }

  async getCities() {
    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/cities`;
    const result = await axios.get(url);

    return result.data;
  }
}
